import moment from "moment-timezone"
import queryString from "query-string"
import React from "react"

import { parse, stringify } from "../components/pages/CreateCampaignInfoPage/linkedin-url-parser"
import {
  hasFirstConnections,
  hasGroupConnections,
  hasSecondConnections,
  hasThirdConnections,
  setFirstConnections,
  setGroupConnections,
  setSecondConnections,
  setThirdConnections,
} from "../components/pages/CreateCampaignInfoPage/query-manipulator"
import { AB_TESTING_ELEMENTS, defaultAutoReuseInterval } from "../constants/campaign-constants"
import { basicLinkedinLink } from "../constants/linkedin-link-constants"
import * as actions from "../redux/actions"
import { history, store } from "../redux/store"
import dataUtils from "./data-utils"
import {
  determineCampaignType,
  firstConnectionsSalesNavNewUrl,
  groupConnectionSalesNavNewUrl,
  recognizedBasicConnection,
  secondConnectionSalesNavNewUrl,
  thirdConnectionSalesNavNewUrl,
} from "./linkedin-url-utils"
import loggerUtils from "./logger-utils"
import {
  checkAndChangeTagReplacements,
  complexStepsSubmit,
  createCampaignSteps,
  hasBigChangeInLink,
  parseComplexReplyToSameThread,
  parseSimpleReplyToSameThread,
} from "./step-utils"
import {
  validateFileSize,
  validateManageCampaign,
  validateScheduleCampaign,
} from "./validation-utils"

const getInitialStateData = (step = {}, hideAdditionalData) => {
  const { activeAccountID } = store.getState().account
  const teamAccounts = store.getState().teamManagement.teamAccounts.items

  const {
    isActivateConnectViaEmailAccordion,
    isActiveInviteToConnectAccordion,
    onlyConnectViaEmail,
    inviteToConnectViaEmail,
    boostConnectViaEmail,
    ultraConnectBoost,
    allowPaidInMails,
  } = store.getState().forms.formData

  const defaultHoursIfEmailOpened = 8
  const days = [""]
  const hours = step.action === "ifEmailOpened" ? [defaultHoursIfEmailOpened] : [""]
  let isOpenedInviteViaEmailAccordion
  let isOpenedInviteToConnectAccordion
  let isActiveSwitchInviteViaEmailAccordion

  if (step.doAfterPreviousStep !== undefined) {
    days[0] = Math.trunc((step.doAfterPreviousStep || 0) / (24 * 60 * 60 * 1000))
    hours[0] = (step.doAfterPreviousStep - days * (24 * 60 * 60 * 1000) || 0) / (60 * 60 * 1000)
  }

  if (
    (step.data && step.data.boostConnectViaEmail) ||
    (step.data && step.data.onlyConnectViaEmail)
  ) {
    isOpenedInviteViaEmailAccordion =
      !isActivateConnectViaEmailAccordion && step.data.boostConnectViaEmail
        ? step.data.boostConnectViaEmail
        : step.data.onlyConnectViaEmail

    isActiveSwitchInviteViaEmailAccordion =
      !isActivateConnectViaEmailAccordion && step.data.boostConnectViaEmail
        ? step.data.boostConnectViaEmail
        : step.data.onlyConnectViaEmail
  }

  if (step.data && step.data.onlyConnectViaEmail) {
    isOpenedInviteToConnectAccordion = !step.data.onlyConnectViaEmail
  }

  if (
    step.data &&
    step.data.connectMessageViaEmail &&
    step.data.connectMessageViaEmail.length > 1 &&
    !isOpenedInviteViaEmailAccordion &&
    !isOpenedInviteToConnectAccordion &&
    !isActiveSwitchInviteViaEmailAccordion
  ) {
    isOpenedInviteViaEmailAccordion = true
    isOpenedInviteToConnectAccordion = true
    isActiveSwitchInviteViaEmailAccordion = true
  }

  let data = {
    ...step.data,
    tagInfos: (step.data && step.data.tagInfo) || [[]],
    personalizedImageIds: (step.data && step.data.personalizedImageId) || [null],
    messages: (step.data && step.data.message) || [""],
    message: "" /** needed for backend validation */,
    subjects: (step.data && step.data.subject) || [""],
    signatures: (step.data && step.data.signature) || [""],
    signatureIds: (step.data && step.data.signatureId) || [""],
    useDefaultSignatures: (step.data && step.data.useDefaultSignature) || [true],
    emailType: (step.data && step.data.emailType) || "",
    sentEmailIfNotConnected: (step.data && step.data.sentEmailIfNotConnected) || false,
    discoverBusinessEmail: (step.data && step.data.discoverBusinessEmail) || undefined,
    outputs: step.outputs || [],
    businessEmails: (step.data && step.data.businessEmails) || true,
    personalEmails: (step.data && step.data.personalEmails) || true,
    inviteMessageOption: (step.data && step.data.personalEmails) || "option_a",
    connectMessageViaEmail: "",
    connectMessageViaEmails: (step.data && step.data.connectMessageViaEmail) || [""],
    isActiveInviteToConnectAccordion:
      step.data && step.data.onlyConnectViaEmail
        ? isOpenedInviteToConnectAccordion
        : isActiveInviteToConnectAccordion ||
          (step.data && step.data.isActiveInviteToConnectAccordion) ||
          true,
    isActivateConnectViaEmailAccordion:
      isActivateConnectViaEmailAccordion ||
      isOpenedInviteViaEmailAccordion ||
      (step.data && step.data.isActivateConnectViaEmailAccordion) ||
      false,
    onlyConnectViaEmail:
      onlyConnectViaEmail || (step.data && step.data.onlyConnectViaEmail) || false,
    inviteToConnectViaEmail:
      inviteToConnectViaEmail ||
      isActiveSwitchInviteViaEmailAccordion ||
      (step.data && step.data.inviteToConnectViaEmail) ||
      false,
    boostConnectViaEmail:
      boostConnectViaEmail || (step.data && step.data.boostConnectViaEmail) || false,
    ultraConnectBoost: ultraConnectBoost || (step.data && step.data.ultraConnectBoost) || false,
    allowPaidInMails: allowPaidInMails || (step.data && step.data.allowPaidInMails) || false,
    step: step.step || undefined,
  }

  if (!hideAdditionalData) {
    data = {
      ...data,
      action: step.action || "view",
      days,
      hours,
      selectedTabId: "1",
      editing: false,
      openImagePersonalizationModal: false,
      personalizedImageData: step.personalizedImageData || [{}],
      loadingMyTemplates: false,
      loadingDefaultTemplate: false,
      uploadLoading: false,
      isEmailSubjectWarningShawn: false,
      imagePersonalizationData: {
        items: [],
        selectedLayerIndex: null,
        currentMessage: "",
        tagInfo: [],
      },
      userImage:
        (
          (
            teamAccounts.find(user => user.id === Number(activeAccountID)) || {
              linkedinUser: {},
            }
          ).linkedinUser || {}
        ).picture ||
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/600px-Circle-icons-profile.svg.png",
      customEmailError: false,
    }
  }

  return data
}

const getTime = (doAfterPreviousStep, field) => {
  const time = { days: 0, hours: 0 }
  if (doAfterPreviousStep !== undefined) {
    time.days = [Math.trunc((doAfterPreviousStep || 0) / (24 * 60 * 60 * 1000))]
    time.hours = [(doAfterPreviousStep - time.days * (24 * 60 * 60 * 1000) || 0) / (60 * 60 * 1000)]
  }
  return time[field]
}

const parseIncomingSteps = steps => {
  const ABGroupElements = []
  let n = 0
  let ABGroup = 0
  while (n < steps.length) {
    if (typeof steps[n].id === "string" && steps[n].id?.includes("reactflow__edge")) {
      ABGroupElements.push(steps[n])
      n++
      // eslint-disable-next-line
      continue
    }
    if (steps[n].nextSteps && steps[n].nextSteps.length > 1) {
      /** If there is AB element after condition or AB element, multiple same nextSteps needs to be removed. */
      const nextStepsValues = [0]
      const newNextSteps = []
      for (let i = 0; i < steps[n].nextSteps.length; i++) {
        if (!nextStepsValues.includes(steps[n].nextSteps[i].step)) {
          nextStepsValues.push(steps[n].nextSteps[i].step)
          if (steps[n].nextSteps[i]?.step) {
            newNextSteps.push(steps[n].nextSteps[i])
          }
        }
      }
      steps[n].nextSteps = newNextSteps
    }

    if (steps[n].ABGroup && ABGroup === steps[n].ABGroup) {
      // eslint-disable-next-line
      const ABGroupElementIndex = ABGroupElements.findIndex(el => el.ABGroup === steps[n].ABGroup)
      ABGroupElements[ABGroupElementIndex] = {
        ...(ABGroupElements[ABGroupElementIndex] || []),
        data: {
          ...getInitialStateData(steps[n]),
          ...(ABGroupElements[ABGroupElementIndex]?.data || []),
          messages: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.messages || []),
            steps[n]?.data?.message,
          ],
          connectMessageViaEmails: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.connectMessageViaEmails || []),
            steps[n]?.data?.connectMessageViaEmail,
          ],
          signatures: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.signatures || []),
            steps[n]?.data?.signature,
          ],
          subjects: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.subjects || []),
            steps[n]?.data?.subject,
          ],
          signatureIds: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.signatureIds || []),
            steps[n]?.data?.signatureId,
          ],
          useDefaultSignatures: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.useDefaultSignatures || []),
            steps[n]?.data?.useDefaultSignature,
          ],
          tagInfos: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.tagInfos || []),
            steps[n]?.data?.tagInfo || [],
          ],
          personalizedImageIds: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.personalizedImageIds || []),
            steps[n]?.data?.personalizedImageId,
          ],
          personalizedImageData: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.personalizedImageData || []),
            steps[n]?.data?.personalizedImageData,
          ],
          hours: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.hours || []),
            getTime(steps[n]?.doAfterPreviousStep, "hours"),
          ],
          days: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.days || []),
            getTime(steps[n]?.doAfterPreviousStep, "days"),
          ],
          message: undefined,
          connectMessageViaEmail: undefined,
          signature: undefined,
          subject: undefined,
          signatureId: undefined,
          useDefaultSignature: undefined,
          tagInfo: undefined,
          personalizedImageId: undefined,
        },
        replyToStep: null,
        ABTestingIds: [...(ABGroupElements[ABGroupElementIndex]?.ABTestingIds || []), steps[n].id],
      }
    } else {
      ABGroupElements.push({
        ...steps[n],
        data: {
          ...getInitialStateData(steps[n]),
          ...steps[n].data,
          messages: [steps[n].data?.message],
          signatures: [steps[n].data?.signature],
          subjects: [steps[n].data?.subject],
          signatureIds: [steps[n].data?.signatureId],
          useDefaultSignatures: [steps[n].data?.useDefaultSignature],
          connectMessageViaEmails: [steps[n].data?.connectMessageViaEmail],
          tagInfos: [steps[n].data?.tagInfo],
          personalizedImageIds: [steps[n].data?.personalizedImageId],
          personalizedImageData: [steps[n].data?.personalizedImageData],
          days: [getTime(steps[n]?.doAfterPreviousStep, "days")],
          hours: [getTime(steps[n]?.doAfterPreviousStep, "hours")],
          message: undefined,
          signature: undefined,
          subject: undefined,
          signatureId: undefined,
          useDefaultSignature: undefined,
          tagInfo: undefined,
          personalizedImageId: undefined,
          connectMessageViaEmail: undefined,
        },
        replyToStep: null,
        ABTestingIds: [steps[n].id],
      })
      if (steps[n].ABGroup) {
        ABGroup = steps[n].ABGroup
      }
    }
    n++
  }

  return ABGroupElements
}

const parseCampaignSteps = (campaignSteps = [], isStepsTree) => {
  const filteredCampaignSteps = campaignSteps.filter(step => Object.keys(step).length > 0)
  const newSteps = []
  let ABGroupCount = 0
  filteredCampaignSteps.forEach(campaignStep => {
    let overWriteID = true
    if (typeof campaignStep.id === "string" && campaignStep.id.includes("dndnode_")) {
      overWriteID = false
    }

    if (AB_TESTING_ELEMENTS.includes(campaignStep.action)) {
      if (campaignStep.data.messages.length > 1) {
        ABGroupCount += 1
      }
      campaignStep.data.messages.forEach((message, messageIndex) => {
        if (message.includes("{{personalizedImage}}")) {
          campaignStep.data.tagInfos[messageIndex].push({
            tag: "personalizedImage",
            replaceWith: "personalizedImage",
          })
        }

        let useDefaultSignature = campaignStep.data.useDefaultSignatures[messageIndex]
        let signatureId = campaignStep.data.signatureIds[messageIndex]
        if (useDefaultSignature === null) {
          useDefaultSignature = false
        }
        if (useDefaultSignature && signatureId) {
          signatureId = ""
        }

        newSteps.push({
          ...campaignStep,
          ABGroup: campaignStep.data.messages.length > 1 ? ABGroupCount : null,
          data: {
            ...campaignStep.data,
            message,
            connectMessageViaEmail: campaignStep.data.connectMessageViaEmails[0],
            signature: campaignStep.data.signatures[messageIndex],
            subject: campaignStep.data.subjects[messageIndex],
            signatureId,
            useDefaultSignature,
            tagInfo: campaignStep.data.tagInfos[messageIndex] || [],
            personalizedImageId: campaignStep.data.personalizedImageIds[messageIndex],
            messages: undefined,
            signatures: undefined,
            subjects: undefined,
            signatureIds: undefined,
            useDefaultSignatures: undefined,
            tagInfos: undefined,
            personalizedImageIds: undefined,
            days: isStepsTree ? campaignStep.data.days[messageIndex] : undefined,
            hours: isStepsTree ? campaignStep.data.hours[messageIndex] : undefined,
            personalizedImageData: undefined,
            connectMessageViaEmails: undefined,
          },
          id:
            overWriteID &&
            campaignStep.ABTestingIds &&
            campaignStep.ABTestingIds[
              messageIndex
            ] /** AB Steps merge multiple steps to one, this is necessary to save their ids */
              ? campaignStep.ABTestingIds[messageIndex]
              : campaignStep.id,
          doAfterPreviousStep: campaignStep.doAfterPreviousStep[messageIndex],
          days: undefined,
          hours: undefined,
          ABTestingIds: undefined,
          replyToStep: campaignStep.replyToStep ? campaignStep.replyToStep[messageIndex] : null,
        })
      })
    } else {
      newSteps.push({
        ...campaignStep,
        doAfterPreviousStep:
          campaignStep.doAfterPreviousStep && campaignStep.doAfterPreviousStep[0],
        data: {
          ...campaignStep.data,
          personalizedImageData: undefined,
          message: "",
          tagInfo: [],
          messages: undefined,
          signatures: undefined,
          subjects: undefined,
          signatureIds: undefined,
          useDefaultSignatures: undefined,
          tagInfos: undefined,
          personalizedImageIds: undefined,
          days: isStepsTree ? campaignStep.data.days[0] : undefined,
          hours: isStepsTree ? campaignStep.data.hours[0] : undefined,
          connectMessageViaEmail: "",
          connectMessageViaEmails: undefined,
        },
      })
    }
  })

  return newSteps
}

const getCreateCampaignRequestData = (additionalData = {}) => {
  const { additionalVariables } = store.getState().campaign
  const { formData } = store.getState().forms

  const startAtTime = formData.startUTCTime
    ? moment
        .tz(
          `${formData.startDate.format("DD MM YYY")} ${formData.startUTCTime}`,
          "DD MM YYY HH:mm",
          moment.tz.guess(),
        )
        .utc()
        .valueOf()
    : 0
  const startAt = moment.unix(startAtTime).utc().valueOf() / 1000

  const data = {
    name: formData.name,
    checkTimestamp: formData.sendPrevious ? moment.utc().valueOf() : 0,
    campaignSteps: parseCampaignSteps(formData.campaignSteps),
    isFirstConnection: formData.isFirstConnection,
    getPersonalInfo: formData.getPersonalInfo,
    enableClickRate: formData.trackEmailsClicks,
    enableOpenRate: formData.trackEmailsOpens,
    onlyPremium: formData.dashboard === "import" ? undefined : formData.onlyPremium,
    onlyOpenInmail: formData.onlyOpenInmail,
    onlyDiscoverLeads: formData.onlyDiscoverLeads,
    onlyUniqueLeads: formData.onlyUniqueLeads,
    noPendingConnections: formData.noPendingConnections,
    onlyConnectViaEmail: formData.onlyConnectViaEmail,
    includeGloballyTargetedLeads: formData.includeGloballyTargetedLeads,
    boostConnectViaEmail: formData.boostConnectViaEmail,
    ultraConnectBoost: formData.ultraConnectBoost,
    allowPaidInMails: formData.allowPaidInMails,
    inviteToConnectViaEmail: formData.inviteToConnectViaEmail,
    isActiveInviteToConnectAccordion: formData.isActiveInviteToConnectAccordion,
    isActivateConnectViaEmailAccordion: formData.isActivateConnectViaEmailAccordion,
    textOnlyEmails: formData.textOnlyEmails,
    leadSourceTypes: [formData.campaignType],
    additionalFields: additionalVariables.map(tag => {
      return tag.tag
    }),
    startAt,
    ...additionalData,
  }

  if (formData.scheduleId) {
    data.scheduleId = formData.scheduleId
  }

  return data
}

const addLeadSources = data => {
  const { formData } = store.getState().forms
  const { draftCampaignId } = store.getState().campaign

  const leadSourceUrl =
    formData.dashboard && formData.dashboard !== "import" ? formData.campaignUrl : ""

  const leadSources = [
    {
      campaignId: draftCampaignId,
      leadSourceUrl,
      dashboard: Number(formData.realDashboard),
      leadSourceType: formData.campaignType,
      autoReuse: formData.autoReuse,
      autoReuseInterval: formData.autoReuseInterval,
    },
  ]

  let newData = { ...data, leadSources }

  if (formData.dashboard && formData.dashboard === "import") {
    const file = formData.file || new File([""], "no_csv_uploaded.csv")
    const fileName = file?.name ? file?.name.replace(/\.csv$/, "") : "file"
    newData = { ...newData, [fileName]: file }
  }

  return newData
}

const objectToFormData = (obj, formData = new FormData(), parentKey = "") => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key] === undefined ? false : obj[key]
      const formKey = parentKey ? `${parentKey}[${key}]` : key

      if (value instanceof File) {
        formData.append(formKey, value)
      } else if (typeof value === "object" || Array.isArray(value)) {
        formData.append(formKey, JSON.stringify(value))
      } else {
        formData.append(formKey, value)
      }
    }
  }
  return formData
}

const prepareForCampaignSubmit = (
  additionalData = {},
  campaignStepsType,
  prepareForCreate = false,
) => {
  const data = getCreateCampaignRequestData(additionalData)
  let newData = data

  if (campaignStepsType === "simple") {
    newData = parseSimpleReplyToSameThread(data)
  }

  if (prepareForCreate) {
    newData = addLeadSources(newData)
  }

  return newData
}

const parseDoAfterPreviousStep = (hours, days) => {
  if (typeof hours !== "object") {
    hours = [hours]
    days = [days]
  }
  return (hours || []).map(
    (time, index) =>
      (Number(days[index]) || 0) * 24 * 60 * 60 * 1000 +
      (Number(hours[index]) || 0) * 60 * 60 * 1000,
  )
}

const filterTagInfos = (tagInfos = [], messages, subjects) => {
  return tagInfos.map((tagInfo, index) =>
    tagInfo?.filter(({ tag }) => {
      const parsedTag = tag.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
      return (
        messages[index]?.includes(`{{${parsedTag}}}`) || subjects[index]?.includes(`{{${tag}}}`)
      )
    }),
  )
}

const getABTestingField = (field, data) => {
  return {
    messages: { field: "messages", data, emptyValue: "" },
    subjects: { field: "subjects", data, emptyValue: "" },
    signatures: { field: "signatures", data, emptyValue: "" },
    tagInfos: { field: "tagInfos", data, emptyValue: [] },
    personalizedImageIds: {
      field: "personalizedImageIds",
      data,
      emptyValue: null,
    },
    personalizedImageData: {
      field: "personalizedImageData",
      data,
      emptyValue: {},
    },
    hours: { field: "hours", data, emptyValue: "" },
    days: { field: "days", data, emptyValue: "" },
    signatureIds: { field: "signatureIds", data, emptyValue: "" },
    useDefaultSignatures: { field: "useDefaultSignatures", data, emptyValue: true },
    connectMessageViaEmails: { field: "connectMessageViaEmails", data, emptyValue: "" },
  }[field]
}
const getABTestingFields = fields =>
  fields.map(field => {
    return getABTestingField(Object.keys(field)[0], field[Object.keys(field)[0]])
  })

const CREATE_CAMPAIGN_PAGE = {
  INFO: "INFO",
  SETTINGS: "SETTINGS",
}

const getCampaignType = dashboard => {
  let campaignType = ""
  switch (dashboard) {
    case "1":
      campaignType = "BASIC"
      break
    case "2":
      campaignType = "SALES_NAVIGATOR"
      break
    case "import":
      campaignType = "CSV"
      break
    case "post-engagement":
      campaignType = "POST_ENGAGEMENT"
      break
    case "leads-list":
      campaignType = "LEADS_LIST"
      break
    case "recruiter":
    case "recruiter-pipeline":
      campaignType = "RECRUITER"
      break
    default:
      break
  }
  return campaignType
}

const getRealDashboard = dashboard => {
  switch (dashboard) {
    case "import":
      return "1"
    case "post-engagement":
      return "1"
    case "recruiter":
    case "recruiter-pipeline":
      return "3"
    case "leads-list":
      return "2"
    default:
      return dashboard
  }
}

const getCreateCampaignInitialStateData = (pageType, formData) => {
  const campaignUrl = formData.campaignUrl || ""

  const searchParams = campaignUrl.substring(campaignUrl.search("\\?"))
  const searchString = queryString.parse(searchParams)

  const dashboard = formData.dashboard || "1"
  const { firstConnection, secondConnection, thirdConnection, groupConnection } = formData
  const connections = {
    firstConnection: firstConnection || false,
    secondConnection: secondConnection || false,
    thirdConnection: thirdConnection || false,
    groupConnection: groupConnection || false,
  }

  if (dashboard === "1") {
    let selectedFacetNetworks = []
    if (searchString.facetNetwork) {
      selectedFacetNetworks = JSON.parse(searchString.facetNetwork)
    }
    if (searchString.network) {
      const network = JSON.parse(searchString.network)
      selectedFacetNetworks = typeof network === "string" ? [network] : network
      selectedFacetNetworks.forEach(facetNetwork => {
        switch (facetNetwork) {
          case "F":
            connections.firstConnection = true
            break
          case "S":
            connections.secondConnection = true
            break
          case "O":
            connections.thirdConnection = true
            break
          default:
            break
        }
      })
    }
  } else if (dashboard === "2") {
    let selectedRelationship = ""
    if (searchString.relationship) {
      selectedRelationship = searchString.relationship
      selectedRelationship.split(",").forEach(relationship => {
        switch (relationship) {
          case "F":
            connections.firstConnection = true
            break
          case "S":
            connections.secondConnection = true
            break
          case "O":
            connections.thirdConnection = true
            break
          case "A":
            connections.groupConnection = true
            break
          default:
            break
        }
      })
    }

    if (searchString.query) {
      if (searchString.query.includes(firstConnectionsSalesNavNewUrl)) {
        connections.firstConnection = true
      }

      if (searchString.query.includes(thirdConnectionSalesNavNewUrl)) {
        connections.thirdConnection = true
      }

      if (searchString.query.includes(secondConnectionSalesNavNewUrl)) {
        connections.secondConnection = true
      }

      if (searchString.query.includes(groupConnectionSalesNavNewUrl)) {
        connections.groupConnection = true
      }
    }
  }

  const onlyUniqueLeads =
    typeof formData.onlyUniqueLeads !== "undefined" ? formData.onlyUniqueLeads : true

  const noPendingConnections =
    typeof formData.noPendingConnections !== "undefined" ? formData.noPendingConnections : true

  const state = {
    [CREATE_CAMPAIGN_PAGE.INFO]: {
      dashboard,
      campaignType: getCampaignType(formData.dashboard || "1"),
      ...connections,
      onlyPremium: formData.onlyPremium || false,
      onlyOpenInmail: formData.onlyOpenInmail || false,
      sendPrevious: formData.sendPrevious || false,
      getPersonalInfo: formData.getPersonalInfo || false,
      textOnlyEmails: formData.textOnlyEmails || false,
      onlyUniqueLeads,
      noPendingConnections,
      trackEmailsClicks: formData.trackEmailsClicks || false,
      trackEmailsOpens: formData.trackEmailsOpens || false,
      includeGloballyTargetedLeads: formData.includeGloballyTargetedLeads || false,
      autoReuse: formData.autoReuse || false,
      autoReuseInterval: formData.autoReuseInterval || defaultAutoReuseInterval,
      loading: false,
      campaignUrl,
      hideJailMessage: false,
      recentSearchIdWarningShown: false,
      startAt: formData.startAt,
      mailboxes: formData.mailboxes || [],
      scheduleId: formData.scheduleId,
      startUTCTime: formData.startUTCTime || undefined,
      startDate: formData.startDate || undefined,
      newUrl: !!searchString.query,
    },
    [CREATE_CAMPAIGN_PAGE.SETTINGS]: {
      dashboard,
      campaignType: getCampaignType(formData.dashboard || "1"),
      ...connections,
      onlyPremium: formData.onlyPremium || false,
      onlyOpenInmail: formData.onlyOpenInmail || false,
      sendPrevious: formData.sendPrevious || false,
      getPersonalInfo: formData.getPersonalInfo || false,
      textOnlyEmails: formData.textOnlyEmails || false,
      onlyUniqueLeads,
      noPendingConnections,
      trackEmailsClicks:
        typeof formData.trackEmailsClicks !== "undefined" ? formData.trackEmailsClicks : false,
      trackEmailsOpens:
        typeof formData.trackEmailsOpens !== "undefined" ? formData.trackEmailsOpens : false,
      includeGloballyTargetedLeads: formData.includeGloballyTargetedLeads || false,
      autoReuse: formData.autoReuse || false,
      autoReuseInterval: formData.autoReuseInterval || defaultAutoReuseInterval,
      loading: false,
      campaignUrl,
      hideJailMessage: false,
      recentSearchIdWarningShown: false,
      startAt: formData.startAt,
      mailboxes: formData.mailboxes || [],
      scheduleId: formData.scheduleId,
      startUTCTime: formData.startUTCTime || "",
      startDate: formData.startDate || "",
    },
  }

  return state[pageType]
}

const inviteViaEmailStepsValidation = campaignSteps => {
  let onlyConnectViaEmail = false
  let boostConnectViaEmail = false

  if (campaignSteps) {
    campaignSteps.forEach(step => {
      if (step.data && step.data.onlyConnectViaEmail) {
        onlyConnectViaEmail = step.data.onlyConnectViaEmail
      }

      if (step.data && step.data.boostConnectViaEmail) {
        boostConnectViaEmail = step.data.boostConnectViaEmail
      }
      return step
    })
  }

  return { onlyConnectViaEmail, boostConnectViaEmail }
}

const inviteViaEmailCsvValidation = csvInfo => {
  let hasColumns = false
  if (csvInfo && csvInfo.items) {
    hasColumns =
      csvInfo.items.includes("profileUrl") && csvInfo.items.includes("linkedinPersonalEmail")
  }
  return hasColumns
}

const checkInviteViaEmailSteps = (allStepsData, type) => {
  if (allStepsData.length) {
    let hasView = false
    let hasFollow = false
    let hasInMail = false
    let hasFindAndVerifyEmailByLinkedin = false
    let hasFindAndVerifyBusinessEmailByYourSource = false
    let hasInviteViaEmail = false

    allStepsData.forEach(step => {
      const stepType = type ? step.type : step.action
      switch (stepType) {
        case "view":
          hasView = true
          break
        case "follow":
          hasFollow = true
          break
        case "inMail":
          hasInMail = true
          break
        case "findAndVerifyEmailByLinkedin":
          hasFindAndVerifyEmailByLinkedin = true
          break
        case "findAndVerifyBusinessEmailByYourSource":
          hasFindAndVerifyBusinessEmailByYourSource = true
          break
        case "connect":
          if (step.data || step.data.boostConnectViaEmail || step.data.onlyConnectViaEmail) {
            hasInviteViaEmail = step.data.boostConnectViaEmail || step.data.onlyConnectViaEmail
          }
          break
        default:
          break
      }
    })
    const allConnectSteps = hasView || hasFollow || hasInMail
    const allVerifySteps =
      hasFindAndVerifyEmailByLinkedin || hasFindAndVerifyBusinessEmailByYourSource

    return { hasInviteViaEmail, allConnectSteps, allVerifySteps }
  }
}

const validateStartTimeAndDateCampaign = async (startDate, startUTCTime, timezone) => {
  actions.clearFormErrors()
  const allErrors = validateScheduleCampaign({ startDate, startUTCTime, timezone })
  actions.setFormErrors(allErrors)
  return !allErrors
}

const createSequence = async (
  accountData,
  formData,
  dashboard,
  getCampaignJSONData,
  userID,
  accountID,
  teamID,
  createCampaignFlow,
  sequence,
  callback,
  isSavedStepTemplate,
) => {
  let isCallbackValid = true

  if (callback) {
    isCallbackValid = await callback()
  }

  if (isCallbackValid) {
    const { startDate, startUTCTime } = formData
    const isValidateStartTimeAndDateCampaign = await validateStartTimeAndDateCampaign(
      startDate,
      startUTCTime,
      moment.tz.guess(),
    )

    if (isValidateStartTimeAndDateCampaign) {
      if (+accountData.linkedinSubscriptionId !== 2 && ["2", "leads-list"].includes(dashboard)) {
        store.dispatch(
          actions.showInfoModal(
            "warning",
            "Warning",
            "You cannot create Sales Navigator campaign with no Sales Navigator subscription on LinkedIn.",
          ),
        )
      } else {
        const formFields = { ...getCampaignJSONData, ...{ saveDraftLoading: true } }
        store.dispatch(actions.updateFormFields(formFields))

        if (dashboard !== "import") {
          await store.dispatch(actions.setAdditionalVariables([]))
        }

        await store.dispatch(actions.updateDraftCampaign())
        store.dispatch(actions.updateFormField("saveDraftLoading", false))

        if (createCampaignFlow) {
          await createCampaignSteps(sequence, [], isSavedStepTemplate)
          await store.dispatch(actions.hideInfoModal())
        }
        history.push(
          `/users/${userID}/teams/${teamID}/accounts/${accountID}/create-campaign/smart-steps`,
        )
      }
    }
  }
}

const createCampaign = async (success, allStepsData, callback, stepTemplate) => {
  if (success) {
    const allStepsDataParsed = allStepsData.map(item => {
      delete item.id
      delete item.campaignId

      if (item.action === "email") {
        return {
          ...item,
          data: {
            ...item.data,
            message: dataUtils.createHTMLEmailTemplate(item.data.message),
          },
        }
      }

      return item
    })

    const allStepsWithTags = allStepsDataParsed.map(step => checkAndChangeTagReplacements(step))

    if (!stepTemplate) {
      store.dispatch(actions.updateFormField("campaignSteps", allStepsWithTags))
    } else {
      store.dispatch(actions.updateFormField("stepTemplate", allStepsWithTags))
    }

    if (callback) {
      await callback()
    }
  }
}

const renderInviteStepsMessage = addingInviteStepsFlow => {
  return (
    <div>
      <p className="pb-2">Before activating Invite via email you must insert either:</p>
      <ul className="invite-connect-message">
        <li className="pb-2 invite-connect-list-message">
          Insert <span className="invite-connect-list-message__word">View Profile</span>,
          <span className="invite-connect-list-message__word"> Follow</span>, OR&nbsp;
          <span className="invite-connect-list-message__word">InMail Message</span>,&nbsp; together
          with&nbsp;
          <span className="invite-connect-list-message__word">
            Find & verify business email via LinkedIn.
          </span>
        </li>
        <li className="pb-2 invite-connect-list-message">
          Use&nbsp;
          <span className="invite-connect-list-message__word">
            Find & verify business email via your source.
          </span>
        </li>
      </ul>
      {addingInviteStepsFlow ? (
        <p className="pb-2">Would you like us to add the necessary steps for you? </p>
      ) : (
        <p className="pb-2">Please check your sequence and add the necessary steps.</p>
      )}
    </div>
  )
}

const onSubmit = async (allElements, callback, setLoading = () => {}, idSelector, stepTemplate) => {
  let success = false
  let allStepsData = []
  let notConnectedSteps = []
  let edges = []
  let newAllStepsData = []
  let hasSquareBrackets = false

  try {
    const complexStepData = await complexStepsSubmit(
      allElements,
      true,
      idSelector,
      undefined,
      stepTemplate,
    )
    success = complexStepData.success
    allStepsData = complexStepData.allStepsData
    notConnectedSteps = complexStepData.notConnectedSteps
    edges = complexStepData.edges
    hasSquareBrackets = complexStepData.hasSquareBrackets

    newAllStepsData = parseComplexReplyToSameThread([...allStepsData, ...edges])
    if (success) {
      if (notConnectedSteps.length && !stepTemplate) {
        store.dispatch(
          actions.showInfoModal(
            "warning",
            "Error",
            `We just remind you that all the steps you didn't connect to the lead source won't be active when you start the campaign.
         Are you sure that you want to create the campaign?`,
            async () => {
              await createCampaign(success, [...newAllStepsData], callback)
            },
            undefined,
            () => {
              setLoading(false)
            },
          ),
        )
      } else if (hasSquareBrackets && !stepTemplate) {
        store.dispatch(
          actions.showInfoModal(
            "warning",
            "Error",
            `Your messages contain an error. Make sure to erase all descriptive placeholders with square brackets and replace them with your custom text or variable.`,
            undefined,
            undefined,
            undefined,
            "Cancel",
            undefined,
            undefined,
            undefined,
            undefined,
            "danger",
            "warning-modal",
          ),
        )
        setLoading(false)
      } else if (allStepsData.length && !stepTemplate) {
        const { hasInviteViaEmail, allConnectSteps, allVerifySteps } = checkInviteViaEmailSteps(
          allStepsData,
          true,
        )
        if (hasInviteViaEmail) {
          if (allConnectSteps && allVerifySteps) {
            await createCampaign(success, newAllStepsData, callback)
          } else {
            store.dispatch(
              actions.showInfoModal(
                "warning",
                "Warning",
                renderInviteStepsMessage(),
                undefined,
                undefined,
                () => {},
                "Cancel",
                true,
                undefined,
                undefined,
                undefined,
                undefined,
                "invite-to-connect-validation-modal",
              ),
            )
            setLoading(false)
          }
        } else {
          await createCampaign(success, newAllStepsData, callback)
          setLoading(false)
        }
      } else {
        await createCampaign(success, newAllStepsData, callback, stepTemplate)
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  } catch (error) {
    loggerUtils.sendErrors(error?.message, allElements)
    store.dispatch(
      actions.showInfoModal(
        "warning",
        "Error",
        `Error occurred when parsing Smart Sequence. Please contact support!`,
        () => {},
        undefined,
        () => {},
      ),
    )
    setLoading(false)
  }
}

const getInputPlaceholder = dashboard => {
  switch (dashboard) {
    case "1":
      return "LinkedIn search URL"
    case "2":
      return "LinkedIn Sales Navigator search URL"
    case "post-engagement":
      return "LinkedIn post engagement URL"
    case "recruiter":
    case "recruiter-pipeline":
      return "Recruiter search URL"
    case "leads-list":
      return "LinkedIn lead list URL"
    default:
      return ""
  }
}

const getIconAndTooltip = leadSourceType => {
  switch (leadSourceType) {
    case "CSV":
      return { icon: "create-campaign-import-csv", tooltip: "Imported CSV" }
    case "RECRUITER":
    case "TALENT_LIST":
      return { icon: "recruiter", tooltip: "Recruiter search URL" }
    case "POST_ENGAGEMENT":
      return { icon: "create-campaign-post-engagement", tooltip: "Post engagement URL" }
    case "LEADS_LIST":
      return { icon: "create-campaign-leads-list", tooltip: "Leads list URL" }
    case "BASIC":
      return { icon: "create-campaign-ln", tooltip: "LinkedIn search URL" }
    case "SALES_NAVIGATOR":
      return { icon: "create-campaign-sales", tooltip: "Sales Navigator search URL" }
    default:
      return { icon: null, tooltip: "" }
  }
}

const parsedSearchString = value => {
  return queryString.parse(
    value.includes("?") ? value.substring(value.search(/\?/)) : value.substring(value.search(/#/)),
  )
}

const recognizedTypeAndConnectionsFromLink = async (
  newSaleNavUrl,
  value,
  dashboard,
  selectedFacetNetworks,
  formData,
) => {
  let newState = {}
  store.dispatch(actions.updateFormField("campaignUrl", value))
  const linkedinSubscriptionId = +(
    formData["linkedin-subscription"] || store.getState().account.accountData.linkedinSubscriptionId
  )

  if (newSaleNavUrl) {
    try {
      const parsedState = parse(value)
      const hasFirstConnection = hasFirstConnections(parsedState.queryObject)
      const hasSecondConnection = hasSecondConnections(parsedState.queryObject)
      const hasThirdConnection = hasThirdConnections(parsedState.queryObject)
      const hasGroupConnection = hasGroupConnections(parsedState.queryObject)
      newState = {
        ...newState,
        campaignUrl: value,
        firstConnection: hasFirstConnection,
        secondConnection: hasSecondConnection,
        thirdConnection: hasThirdConnection,
        groupConnection: hasGroupConnection,
        ...determineCampaignType(value, dashboard, linkedinSubscriptionId, newState),
      }

      if (hasFirstConnection && (hasSecondConnection || hasThirdConnection)) {
        newState = {
          ...newState,
          campaignUrl: value,
          firstConnection: false,
          secondConnection: false,
          thirdConnection: false,
          groupConnection: false,
        }

        setFirstConnections(parsedState.queryObject, newState.firstConnection)
        setSecondConnections(parsedState.queryObject, newState.secondConnection)
        setThirdConnections(parsedState.queryObject, newState.thirdConnection)
        setGroupConnections(parsedState.queryObject, newState.groupConnection)

        const stringifiedUrl = stringify(parsedState)

        store.dispatch(actions.updateFormField("campaignUrl", stringifiedUrl))
        newState = {
          ...newState,
          campaignUrl: stringifiedUrl,
        }
      }
    } catch {}
  } else {
    newState = {
      campaignUrl: value,
      firstConnection: false,
      secondConnection: false,
      thirdConnection: false,
      groupConnection: false,
      ...recognizedBasicConnection(selectedFacetNetworks, newState),
      ...determineCampaignType(value, dashboard, linkedinSubscriptionId, newState),
    }
  }
  return newState
}

const getSelectedFacetNetworks = searchString => {
  let selectedFacetNetworks = []
  let newSaleNavUrl = false
  if (searchString.facetNetwork) {
    selectedFacetNetworks = JSON.parse(searchString.facetNetwork)
  } else if (searchString.network) {
    const network = JSON.parse(searchString.network)
    selectedFacetNetworks = typeof network === "string" ? [network] : network
  } else if (searchString.query) {
    newSaleNavUrl = true
    selectedFacetNetworks = searchString.query
  } else if (searchString.relationship) {
    selectedFacetNetworks = searchString.relationship.split(",")
  }

  return { selectedFacetNetworks, newSaleNavUrl }
}

const linkedinOnChange = async (value, state, setState) => {
  setState({ newSaleNavUrl: false })

  const previousFirstConnection = state.firstConnection
  const { recentSearchIdWarningShown, dashboard } = state

  const searchString = parsedSearchString(value)
  store.dispatch(actions.clearFormError())

  if (searchString.recentSearchId && !recentSearchIdWarningShown) {
    store.dispatch(
      actions.showInfoModal(
        "warning",
        "Warning",
        "Be careful, if you use a saved search URL from a different LinkedIn account this campaign won't discover leads.",
      ),
    )
    setState({ recentSearchIdWarningShown: true })
  }

  const { selectedFacetNetworks, newSaleNavUrl } = getSelectedFacetNetworks(searchString)
  let firstConnections = "F"
  if (newSaleNavUrl) {
    setState({ newSaleNavUrl })
    firstConnections = firstConnectionsSalesNavNewUrl
  }

  const { formData } = store.getState().forms
  await hasBigChangeInLink(
    previousFirstConnection,
    selectedFacetNetworks.includes(firstConnections),
    formData.campaignSteps,
    async () => {
      const newState = await recognizedTypeAndConnectionsFromLink(
        newSaleNavUrl,
        value,
        dashboard,
        selectedFacetNetworks,
        formData,
      )
      setState(newState)
    },
  )
}

const checkboxChanges = async (name, state, formData) => {
  const { newSaleNavUrl } = state
  const value = !state[name]
  const previousFirstConnection = state.firstConnection
  const newFirstConnection = name === "firstConnection" && value

  let newState = {}
  await hasBigChangeInLink(
    previousFirstConnection,
    newFirstConnection,
    formData.campaignSteps,
    async () => {
      if (newFirstConnection) {
        newState = {
          ...newState,
          ...{
            firstConnection: true,
            secondConnection: false,
            thirdConnection: false,
          },
        }
      } else if (name === "groupConnection") {
        newState = {
          ...newState,
          ...{
            [name]: value,
          },
        }
      } else {
        newState = {
          ...newState,
          ...{
            firstConnection: false,
            [name]: value,
          },
        }
      }

      const nextState = { ...state, ...newState }
      let newCampaignLink = state.campaignUrl

      if (newCampaignLink) {
        const searchParams = newCampaignLink.includes("?")
          ? newCampaignLink.substring(newCampaignLink.search("\\?"))
          : newCampaignLink.substring(newCampaignLink.search("\\#"))
        let searchKeywords

        if (searchParams) {
          const searchString = queryString.parse(searchParams)
          const { firstConnection, secondConnection, thirdConnection, groupConnection, dashboard } =
            nextState

          const salesNavigatorLink = dashboard === "2"
          const basicLink = dashboard === "1"

          if (basicLink) {
            const facetNetworks = []
            if (firstConnection) {
              facetNetworks.push(basicLinkedinLink.firstConnection)
            } else {
              if (secondConnection) facetNetworks.push(basicLinkedinLink.secondConnection)
              if (thirdConnection) facetNetworks.push(basicLinkedinLink.thirdConnection)
            }

            if (searchString.facetNetwork) {
              searchString.facetNetwork = JSON.stringify(facetNetworks)
            } else {
              searchString.network = JSON.stringify(facetNetworks)
            }
          } else if (salesNavigatorLink) {
            if (newSaleNavUrl) {
              try {
                const val = parse(newCampaignLink)
                setFirstConnections(val.queryObject, firstConnection)
                setSecondConnections(val.queryObject, secondConnection)
                setThirdConnections(val.queryObject, thirdConnection)
                setGroupConnections(val.queryObject, groupConnection)

                const stringifiedUrl = stringify(val)

                store.dispatch(actions.updateFormField("campaignUrl", stringifiedUrl))
                newState = {
                  ...nextState,
                  campaignUrl: stringifiedUrl,
                }

                return newState
              } catch {}
            } else {
              let relationship = ""
              if (firstConnection) {
                relationship += relationship.length > 0 ? ",F" : "F"
              } else {
                if (secondConnection) relationship += relationship.length > 0 ? ",S" : "S"
                if (thirdConnection) relationship += relationship.length > 0 ? ",O" : "O"
              }

              if (groupConnection) relationship += relationship.length > 0 ? ",A" : "A"

              searchString.relationship = relationship
            }
          } else if (dashboard === "recruiter") {
            if ((name === "secondConnection" || name === "thirdConnection") && value) {
              store.dispatch(actions.updateFormFields({ [name]: value, firstConnection: false }))
            } else if (name === "firstConnection" && value) {
              store.dispatch(
                actions.updateFormFields({
                  firstConnection: true,
                  secondConnection: false,
                  thirdConnection: false,
                }),
              )
            } else {
              store.dispatch(actions.updateFormField(`${name}`, value))
            }
          }

          const queryStringData = queryString.stringify(searchString)
          const newFilters = decodeURI(queryStringData)
            .replaceAll(" ", "%2520")
            .replaceAll("%2B", "%252B")
            .replaceAll("%20", "%2520")
            .replaceAll("%C5", "%25C5")
            .replaceAll("%25C5%8", "%25C5%258")
            .replaceAll("%22%2520", "%2522%2520")
            .replaceAll("%22", "%2522")
            .replaceAll("%29", "%2529")
            .replaceAll("%28", "%2528")
            .replaceAll("%22", "%2522")
            .replaceAll("'", "%2527")
            .replaceAll("%C3%", "%25C3%25")
            .replaceAll("%2C%2520", "$252C%2520")
            .replaceAll("%26", "%2526")
            .replaceAll("%27", "%2527")
            .replaceAll("%E2", "%25E22")
            .replaceAll("%80", "%2580")
            .replaceAll("%8B", "%258B")
            .replaceAll("%24252C%2520", "%252C%2520")
            .replaceAll("$252C", "%252C")
            .replaceAll("%25E22", "%25E2")

          const firstPartSearchParam = newFilters.substring(0, newFilters.indexOf("keywords") - 5)
          const sessionPartUrl = newFilters.substring(newFilters.indexOf("&sessionId"))
          let finalUrl

          if (!!newSaleNavUrl && newFilters.includes("keywords")) {
            finalUrl = `${firstPartSearchParam}${encodeURI(searchKeywords)}${sessionPartUrl}`
          } else {
            finalUrl = `${newFilters}${sessionPartUrl}`
          }

          const baseLink = newCampaignLink.includes("?")
            ? newCampaignLink.substring(0, newCampaignLink.search("\\?"))
            : newCampaignLink.substring(0, newCampaignLink.search("\\#"))

          newCampaignLink = `${baseLink}${newCampaignLink.includes("?") ? "?" : "#"}${
            newSaleNavUrl ? (firstPartSearchParam ? finalUrl : newFilters) : queryStringData
          }`

          store.dispatch(actions.updateFormField("campaignUrl", newCampaignLink))
          newState = {
            ...nextState,
            ...{
              campaignUrl: newCampaignLink,
            },
          }

          return newState
        }
      }
    },
  )
  return newState
}

const validateFields = async (campaignState, formData, campaignName) => {
  const {
    campaignUrl,
    dashboard,
    firstConnection,
    secondConnection,
    thirdConnection,
    groupConnection,
    newSaleNavUrl,
  } = campaignState

  const { file } = formData
  const name = campaignName || formData.name

  const isAnyOfConnectionDegreeChecked =
    firstConnection || secondConnection || thirdConnection || groupConnection

  store.dispatch(actions.clearFormErrors())
  const allErrors = validateManageCampaign({
    name,
    campaignUrl,
    dashboard,
    file,
    isAnyOfConnectionDegreeChecked,
    newSaleNavUrl,
  })
  const fileErrors = validateFileSize(file)
  const errors = { ...allErrors, ...fileErrors }
  store.dispatch(actions.setFormErrors(errors))
  if (Object.keys(errors).length !== 0) {
    return false
  }

  if (dashboard === "import" && file && Object.keys(errors).length === 0) {
    const importValidationResult = await store.dispatch(actions.importValidation(file))
    return importValidationResult
  }

  return true
}

const validateNewURL = (newState, previousConnections) => {
  const {
    campaignType: prevCampaignType,
    dashboard: prevDashboard,
    firstConnection: prevFirstConnection,
    secondConnection: prevSecondConnection,
    thirdConnection: prevThirdConnection,
  } = previousConnections
  const { campaignType, dashboard, firstConnection, secondConnection, thirdConnection } = newState

  let sameConnections = true
  if (
    (firstConnection && (prevSecondConnection || prevThirdConnection)) ||
    (prevFirstConnection && (secondConnection || thirdConnection))
  ) {
    sameConnections = false
  }

  if (!sameConnections || dashboard !== prevDashboard || campaignType !== prevCampaignType) {
    return false
  }

  return true
}

const submitURL = async (campaignID, campaignState, campaignDetails, accountData, formData) => {
  const { dashboard, campaignUrl } = campaignState
  const { name } = campaignDetails
  const isValidated = await validateFields(campaignState, formData, name)
  const { autoReuse, autoReuseInterval } = formData

  if (isValidated) {
    if (+accountData.linkedinSubscriptionId !== 2 && ["2", "leads-list"].includes(dashboard)) {
      store.dispatch(
        actions.showInfoModal(
          "warning",
          "Warning",
          "You cannot create Sales Navigator campaign with no Sales Navigator subscription on LinkedIn.",
        ),
      )
    } else if (dashboard !== "import" && !campaignUrl.startsWith("https://")) {
      store.dispatch(actions.showInfoModal("error", "Error", "URL doesn't contain https://"))
    } else if (dashboard !== "import" && campaignUrl.includes("savedSearchId")) {
      store.dispatch(
        actions.showInfoModal(
          "error",
          "Error",
          "URL can't be Saved Search. Please convert your Saved Search URL to normal URL.",
        ),
      )
    } else {
      const campaignType = getCampaignType(dashboard)
      return store.dispatch(
        actions.createLeadSource({
          campaignId: +campaignID,
          dashboard,
          campaignType,
          autoReuse,
          autoReuseInterval,
        }),
      )
    }
  }
  return false
}

const getPreviousConnections = async (campaignDetails, formData) => {
  const { leadSources } = campaignDetails
  const leadSource = leadSources?.find(item => item.leadSourceUrl !== "")
  const searchString = parsedSearchString(leadSource?.leadSourceUrl || "")

  const { selectedFacetNetworks, newSaleNavUrl } = getSelectedFacetNetworks(searchString)

  const result = await recognizedTypeAndConnectionsFromLink(
    newSaleNavUrl,
    leadSource?.leadSourceUrl || "",
    leadSource?.dashboard,
    selectedFacetNetworks,
    formData,
  )

  return result
}

const hasEmailInSteps = allElements => {
  if (allElements.length) {
    let hasEmail = false

    allElements.forEach(step => {
      switch (step.type) {
        case "email":
          hasEmail = true
          break

        default:
          break
      }
    })
    return hasEmail
  }
}

const findLatestLeadSourceTypeOfCampaign = sources => {
  if (!sources?.length) {
    return null
  }

  const latestLeadSource = sources.reduce((latest, current) => {
    const latestCreatedAt = new Date(latest.createdAt)
    const currentCreatedAt = new Date(current.createdAt)

    if (currentCreatedAt > latestCreatedAt) {
      return current
    }
    return latest
  })

  return latestLeadSource.leadSourceType
}

export {
  CREATE_CAMPAIGN_PAGE,
  getCreateCampaignRequestData,
  addLeadSources,
  objectToFormData,
  prepareForCampaignSubmit,
  parseIncomingSteps,
  parseCampaignSteps,
  parseDoAfterPreviousStep,
  filterTagInfos,
  getABTestingField,
  getABTestingFields,
  getInitialStateData,
  getCampaignType,
  getRealDashboard,
  getCreateCampaignInitialStateData,
  inviteViaEmailStepsValidation,
  inviteViaEmailCsvValidation,
  checkInviteViaEmailSteps,
  validateStartTimeAndDateCampaign,
  createSequence,
  onSubmit,
  renderInviteStepsMessage,
  getInputPlaceholder,
  getIconAndTooltip,
  linkedinOnChange,
  checkboxChanges,
  validateFields,
  submitURL,
  getPreviousConnections,
  validateNewURL,
  hasEmailInSteps,
  findLatestLeadSourceTypeOfCampaign,
}
